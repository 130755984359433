import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from '@fuzz/directives/fuzz-if-on-dom/fuzz-if-on-dom.directive';
import { FuseInnerScrollDirective } from '@fuzz/directives/fuzz-inner-scroll/fuzz-inner-scroll.directive';
import { FusePerfectScrollbarDirective } from '@fuzz/directives/fuzz-perfect-scrollbar/fuzz-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from '@fuzz/directives/fuzz-mat-sidenav/fuzz-mat-sidenav.directive';

@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective
    ]
})
export class FuseDirectivesModule
{
}
