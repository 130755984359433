<div class="fuzz-countdown">

    <div class="time days">
        <div class="value">
            {{countdown.days}}
        </div>
        <div class="title">
            days
        </div>
    </div>

    <div class="time hours">
        <div class="value">
            {{countdown.hours}}
        </div>
        <div class="title">
            hours
        </div>
    </div>

    <div class="time minutes">
        <div class="value">
            {{countdown.minutes}}
        </div>
        <div class="title">
            minutes
        </div>
    </div>

    <div class="time seconds">
        <div class="value">
            {{countdown.seconds}}
        </div>
        <div class="title">
            seconds
        </div>
    </div>

</div>