<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href="http://www.boendermaker.de" target="_blank">Boendermaker.de</a>
            <span>&bull;</span>
            <a mat-button href="mailto:info@boendermaker.de">E-Mail</a>
        </div>

    </div>

</mat-toolbar>
